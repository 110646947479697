<template>
  <section class="index-form__wrap bottom-form">
    <h2 class="index-form__title">Форма обратной связи</h2>
    <form class="index-form" id="index-form">
      <div class="input-textarea-wrap">
        <div class="index-form__input">
        <input type="text" class="index-form__input-name name" name="name" placeholder="Имя">
        <input type="tel" class="input-form__input-phone telnum tel" name="phone" placeholder="Телефон">
        </div>
        <textarea class="index-form__textarea" name="text" placeholder="Опишите суть вопроса или проблемы"></textarea>
      </div>
      <div class="index-form__btn">
        <button id="form-index-reset" class="button" type="reset">ОЧИСТИТЬ</button>
        <button id="form-index-btn" class="btn-prevent" @click.prevent="sendFormBtn($event.target)">ОТПРАВИТЬ СООБЩЕНИЕ</button>
      </div>
      <a href="/sogl.html" target="blank" class="form-sogl-bf"><span>Нажимая "ОТПРАВИТЬ СООБЩЕНИЕ", Вы принимаете условия "Пользовательского
          соглашения".</span>
      </a>
    </form>
  </section>
</template>

<script>
import { sendForm } from '../../../public/js/mail.js'
export default {
  methods:{
    sendFormBtn(e){
      sendForm(e)
    }
  }
}
</script>

<style>
.index-form__wrap {
    padding:1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.index-form__title{
  font-size: clamp(1rem,2vw, 2rem);
  font-family: var(--title-font);
  font-weight: 400;
  color: var(--light-text);
  text-transform: uppercase;
  margin:0;
}
.index-form {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80%;
    flex-wrap: wrap;
}
.input-textarea-wrap{
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 2% 0;
}
.index-form__input {
    display: flex;
    flex-direction: column;
    flex-basis: 42%;
    justify-content: space-between;
}
.index-form__input input, button{
    outline: none;
    background: rgba(41, 41, 41, 0.9);
    backdrop-filter: blur(5px);
    box-shadow: -1px -1px 10px rgba(0, 0, 0, 0.5), 1px 1px 7px rgba(0, 0, 0, 0.5);
    border: none;
    font-size: 1rem;
    padding: 5px 10px;
    color: whitesmoke;
    height: 2.2rem;
    font-family: 'Commissioner', sans-serif;
    transition: transform 0.3s ease-in-out;
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
    height: 3rem;
}
.index-form__textarea {
    height: calc(6rem + 20px);
    outline: none;
    background: rgba(41, 41, 41, 0.9);
    backdrop-filter: blur(5px);
    box-shadow: -1px -1px 10px rgba(0, 0, 0, 0.5), 1px 1px 7px rgba(0, 0, 0, 0.5);
    border: none;
    font-size: 1rem;
    font-family: 'Commissioner', sans-serif;
    transition: transform 0.3s ease-in-out;
    padding: 1rem;
    box-sizing: border-box;
    flex-basis: 56%;
    color: whitesmoke;
}
.index-form__btn {
    width: 100%;
    display: flex;
    justify-content: space-between;
}
#form-index-reset {
    width: 42%;
    padding: 1rem;
    height: auto;
    font-size: 1.2rem;
}
#form-index-btn {
    width: 56%;
    padding: 1rem;
    height: auto;
    font-size: 1.2rem;
    margin-right: 0;
}
.form-sogl-bf{
  font-size: .8rem;
  font-style: italic;
  padding: 1rem 0;
  color: var(--light-text);
}

@media(max-width:780px){
  .index-form__title{
  font-size: clamp(1rem,3vh, 2rem);
  text-align: center;
  }
  .input-textarea-wrap{
    flex-direction: column;
  }
  .index-form__input input{
    margin:0 0 5px 0;
  }
  .index-form__btn{
    flex-wrap: wrap;
  }
  #form-index-btn, #form-index-reset{
    width: 100%;
    margin: 0 0 5px 0;
    font-size: clamp(1rem, 2vh,2rem);
  }
  .form-sogl-bf{
    padding: 10px 0;
  }
  .form-sogl-bf span{
    font-size: 12px;
  }
}
</style>