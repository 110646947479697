<template>
  <div class="cases-page">
    <div class="cases-page-baner">
      <div class="cases-page-baner__title">КЕЙСЫ</div>
    </div>
    <section class="case-block" v-for="(item,index,key) in cases" :key="key">
        <h2 class="case-title">{{item.title}}</h2>
        <p class="case-text" v-for="text in item.text" :key="text">{{text}}</p>
        <hr v-if="key<=1"/>
    </section>
    
  </div>
</template>

<script>
export default {
data(){
    return{
        cases:this.$store.state.cases
    }
}
}
</script>

<style>
.cases-page{
  background:url(@/../public/img/tekstura-bumagi.png) center/cover no-repeat;
}
.cases-page-baner{
  height: 200px;
  background:linear-gradient(180deg, #000000b2 30%,#444444b1), url(@/../public/img/belii-kirpichni.jpg) no-repeat;
  background-size: cover;
  background-position: top;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cases-page-baner__title{
  font-size: clamp(24px,10vh,5rem);
  font-family: var(--title-font);
  color: var(--light-text);
  font-weight: 700;
  letter-spacing: 2px;
  text-shadow: 1px 1px 5px rgba(0, 0, 0, 1);
}
.case-block{
  padding: 1rem 10%;
  text-align: justify;
  box-sizing: border-box;
  font-size: clamp(14px,2.5vh,1.5rem);
}
.case-title{
  font-size: clamp(1rem,4vh,2rem);
  font-weight: 500;
  margin: 0;
  padding: 2rem 0;
  text-align: center;
}
@media(max-width:800px){
    .case-block{
        text-align: unset;
        font-size: clamp(1.2rem,3vh,2rem);
    }
    .case-block h2{
      font-size: clamp(1.5rem,4vh,2rem);
      font-weight: 400;
      text-align: left;
    }
    
}
@media (prefers-color-scheme: dark){
  .cases-page{
   color: var(--light-text);
   background:linear-gradient(30deg, #000000c7,#515151b9,#060606c6), url(@/../public/img/tekstura-bumagi.png) no-repeat;
}
}
</style>