<template>
  <AboutPage/>
</template>

<script>
import AboutPage from '../components/about/AboutPage.vue'
export default {
  components:{AboutPage}

}
</script>

<style>

</style>
