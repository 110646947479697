<template>
  <div class="usluga-content">
    <h1 class="usluga-name">{{usluga.name}}</h1>
    <!-- <p class="usluga-text usluga-taina-listtitle">{{usluga.tainalist.listTitle}}</p> -->
    <p class="usluga-text" v-for="item in usluga.text" :key="item">{{item}}</p>
    <p class="usluga-text usluga-taina-title">{{usluga.tainaList.title}}</p>
    <p class="usluga-text usluga-taina-listtitle">{{usluga.tainaList.listTitle}}</p>
    <ol class="taina-list">
        <li class="taina-list__li" v-for="li in usluga.tainaList.list" :key="li">{{li}}</li>
    </ol>
    <p class="usluga-taina-listtext">{{usluga.tainaList.listText}}</p>
    <div class="usluga-dist">{{usluga.dist}}</div>
  </div>
</template>

<script>
export default {
data(){
    return{
        usluga:this.$store.state.uslugi.usluga3,
    }
 }
}
</script>

<style>
.usluga-content{
    margin: 0;
    padding: 2rem 10%;
    font-size: clamp(1rem, 3vh, 1.5rem);
    text-align: justify;
}
.usluga-name{
    margin: 0;
    padding: 2rem 0;
    font-family: var(--title-font);
}
.usluga-dist{
    font-style: italic;
    padding-top: 2vh;
    font-size: clamp(1rem, 2vh, 1.5rem);
}
.usluga-text{
    margin: 0;
    padding: 2px 0;
    text-indent: 15px;
}
</style>