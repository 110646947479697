<template>
  <CasesPage/>
</template>

<script>
import CasesPage from '../components/cases/CasesPage.vue'
export default {
    components:{CasesPage}
}
</script>

<style>

</style>