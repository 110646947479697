<template>
  <div class="main-tren">
    <h2 class="main-tren-title">тренинги</h2>
    <div class="main-tren-card" v-for="tren in tren" :key="tren">
        <div class="main-tren-card__title">{{tren.name.replace(';','')}}</div>
        <div class="main-tren-card__btn"><router-link :to="tren.href" @click="topScroll()">Подробнее</router-link></div>
    </div>
  </div>
</template>

<script>
export default {
data(){
    return{
        tren:this.$store.state.trening
    }
},
methods:{
    topScroll(){
        window.scrollTo(0,0)
    }
}
}
</script>

<style>
.main-tren{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding:5%;
}
.main-tren-title{
    text-transform: uppercase;
    margin: 0;
    width: 100%;
    text-align: center;
    font-size: clamp(1rem, 6vh, 3rem);;
    font-family: var(--title-font);
    padding-bottom: 3rem;
    color: var(--dark-text);
}
.main-tren-card{
    background: rgba(255, 255, 255, 0.6);
    backdrop-filter: blur(5px);
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
    width: 27%;
    max-width: 360px;
    min-width: 240px;
    height: 200px;
    margin: 10px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    transition: transform .5s;
}
.main-tren-card:hover{
    transform: scale(1.02);
}
.main-tren-card__title{
    font-size: clamp(1rem, 4vh, 2rem);
    text-align: center;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: var(--menu-font);
    flex-basis: 100%;
}
.main-tren-card__btn{
    width: fit-content;
    border:1px solid var(--dark-text);
    padding: 10px;
    font-size: clamp(1rem, 1vw, 2rem);
    text-transform: uppercase;
    text-align: center;
    margin: 1rem 0;
    transition: .7s;
    align-self: flex-end;
}
.main-tren-card__btn:hover{
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.7);
    background:linear-gradient(50deg, #dbdbdb38,#9d9d9d4d,#f3f1f177);
    text-shadow: 0 0 3px whitesmoke;
}
.main-tren-card__btn a{
    color: var(--dark-text);
}
@media(max-width:960px){
    .main-tren-title{
        padding: 2rem 0;
    }
}
@media (prefers-color-scheme: dark) {
.main-tren-title,.main-tren-card__title{
    color: var(--light-text);
}
.main-tren-card{
    background: rgba(13, 13, 13, 0.5);
}
.main-tren-card__btn{
    border:1px solid var(--light-text);
    
}
.main-tren-card__btn a{
    color: var(--light-text);
}
}
</style>