<template>
  <div class="top-bar" id="top-bar">
    <div class="top-bar__logo"><router-link to="/">UDELA.RU</router-link></div>
    <div class="top-bar__contact">
      <div class="top-bar__icon" v-for="icon in this.$store.state.contact" :key="icon">
        <a :href="icon.href" target="_blank" rel="noopener noreferrer"  @mouseover="iconMouseOver($event, icon)" @mouseout="iconMouseOut($event)"><i :class="icon.icon" :title="icon.name"></i></a>
      </div>
      <a class="top-bar__phone" href="tel:79787045157">+7 (978) 704-51-57</a>
      <i class="fa fa-bars" @click="$emit('showMenu')"></i>
    </div>
  </div>
</template>

<script>

export default {
  methods: {
    iconMouseOver(event,i){ 
      event.target.style = `color:${i.color}`;
    },
    iconMouseOut(event){
      event.target.style = `color:`;
    }
  },
}
</script>

<style>
.top-bar{
  background-color: rgb(20, 20, 20);
  padding: .5rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: whitesmoke;
}
.top-bar__logo a{
  font-family: var(--logo-font);
  font-size: clamp(1rem,1.8vw,2rem);
  color: var(--light-text);
}
.top-bar__contact{
  display: flex;
  font-size: clamp(1rem,1.5vw,2rem);
  line-height: clamp(1rem,1.5vw,2rem);
  font-weight: 600;
}
.top-bar__phone{
  padding:0 2rem;
  font-family: var(--menu-font);
  color: var(--light-text);
  opacity: .8;
  transition: opacity .5s;
}
.top-bar__phone:hover{
  opacity: 1;
}
.top-bar__icon i{
  color: whitesmoke;
  padding-left: 1rem;
  transition: all 1.5s;
  opacity: .8;
}
.top-bar__icon i:hover{
  opacity: 1;
}
@media (max-width: 960px){
  .top-bar__phone{
    display: none;
  }
  .top-bar__icon{
    display: none;
  }
  .top-bar__logo a{
    font-family: var(--logo-font);
    font-size: clamp(1rem,7vw,5rem);
  }
  .fa-bars{
    font-size: clamp(1rem,7vw,5rem);
  }
}
</style>