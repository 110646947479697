<template>
  <div class="about-page">
    <section class="about-znanie" id="znanie" >
        <div class="about-section-baner">
            <div class="about-section-baner__title">ЗНАНИЕ</div>
        </div>
        <div class="about-section" v-for="(item, index) in znanie" :key="item">
            <div class="about-section-title">{{index}}</div>
            <div class="about-section-text">{{item}}</div>
        </div>
    </section>
    <diplom-blok class="diplom-blok-wrap"/>
    <div class="about-znanie-nauka">
        <h3 class="znanie-nauka-title">Научные публикации</h3>
        <div class="index-counter__wrap">
            <div class="index-counter__title">
                <a href="https://elibrary.ru/author_counter_click.asp?id=802579" target="_blank">
                    <span> Профиль на ELIBRARY.RU <br> Индекс цитируемости</span>
                </a>
            </div>
            <div class="index-counter__a">
              <a href="https://elibrary.ru/author_counter_click.asp?id=802579" target="_blank">
                <img src="https://elibrary.ru/author_counter.aspx?id=802579&amp;rand=" title="Профиль автора в Science Index">
              </a>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import DiplomBlok from './DiplomBlok.vue'
export default {
  components: { DiplomBlok },
    data(){
        return{
            znanie:this.$store.state.about.znanie
        }
    }
}
</script>

<style>
.about-znanie {
    padding-bottom: 2rem;
}
.about-section-baner__title{
    backdrop-filter: blur(5px);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}
.about-section {
    padding: 1rem 10vw;
}
.about-section-title {
    font-family: var(--menu-font);
    font-size: clamp(1.5rem, 4vh, 3rem);
    font-weight: 700;
    text-align: center;
    padding-bottom: 1rem;
}
.about-section-text {
    font-size: large;
}
.diplom-blok-wrap{
    padding: 2rem 0;
}
.about-znanie-nauka {
    padding: 3rem;
}
.znanie-nauka-title {
    font-size: clamp(1.5rem,3vh,2rem);
    font-family: var(--title-font);
    text-align: center;
}
.index-counter__wrap {
    display: flex;
    justify-content: center;
    align-items: center;
}
.index-counter__title a{
    color: rgb(47, 47, 63);
    text-decoration: none;
    font-size: clamp(1.5rem,3vh,2rem);
}
.index-counter__a {
    padding-left: 1rem;
}
.index-counter__a img{
    height: 50px;
}
</style>