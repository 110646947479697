<template>
    <section class="about-opyt" id="opyt" >
        <div class="about-section-baner">
            <div class="about-section-baner__title">ОПЫТ</div>
        </div>
        <div class="about-opyt-content">
            <p class="about-opyt-text">Практикую с 1998 года. Во многом разбираюсь, но экспертно – в уголовном и трудовом праве.</p>
            <p class="about-opyt-text">Уголовным правом заинтересовалась еще в университете, но исключительно потому, что все уголовно-правовые дисциплины давались мне особенно легко, казались самыми логичными, интуитивно понятными и последовательными. Однако связывать свою жизнь исключительно с уголовным правом не собиралась.</p>
            <p class="about-opyt-text">Всё изменилось летом, перед пятым курсом, когда я случайно попала на практику в одну из окружных московских прокуратур. Проведя лето в качестве «ног», «скоросшивателя» и «печатной машинки» следователей и помощников прокурора, я поняла, что не смогу стать профессиональным юристом, не изучив систему «в полном объеме». Уровень профессионализма сотрудников прокуратуры казался мне недосягаемым и, как это часто бывает у молодых специалистов, я начала с более, на первый взгляд, простого шага к своей цели.</p>
            <p class="about-opyt-text">Начала работать юрисконсультом в инновационном на тот момент «лужковском» отделе правовой информации одной из библиотечных систем города Москвы. Получила неоценимый опыт поиска и систематизации огромного объема информации, консультирования, оказания скорой правовой помощи населению и пр. Благодаря приобретенным компетенциям, отлично сдала на кандидатский минимум и начала работу над диссертацией. Ну, и решила, что готова… Готова стать настоящим профессионалом.</p>
            <img src="img/photo_prokuror.jpg" class="about-opyt-img" alt="">
            <p class="about-opyt-text">Несмотря на то, что, поступая на службу в органы прокуратуры, искренне полагала, что «попаду» на «общий» надзор, то есть надзор за соблюдением федерального законодательства, так как, например, в трудовом праве уже на тот момент ориентировалась выше среднего, тем не менее, с первых дней службы, как мне тогда казалось, меня «кинули» на государственное обвинение. Несомненно, «матчасть» уголовного права знала на отлично, уголовный процесс хуже, но не плохо, однако сам факт необходимости выступления в судебных заседаниях вселял обоснованный ужас. Повезло, что в университете не с первого раза на отлично сдала «Судебную риторику» и «пришлось» копать до истоков, изучать труды А.Ф. Кони, Ф.Н. Плевако и многих других выдающихся юристов. Как и характерно для студентов, искренне считала, что мне это не пригодится, хотя было более чем просто интересно.</p>
            <p class="about-opyt-text">Итак, оказавшись в транспортной прокуратуре и охватывая в своей деятельности почти все виды надзора, значительную часть времени занимало именно поддержание государственного обвинения. «Эксклюзивность» заключалась в специфике прокуратуры, в отличие от помощников межрайонных прокуроров, гражданские и уголовные дела с участием транспортных прокуроров слушались более чем в двенадцати судах города Москвы. Таким образом, мое «боевое крещение» началось с самого неоднозначного, но поучительного Басманного районного суда г. Москвы, затем Замоскворецкого, Симоновского, Перовского, Люблинского и пр. Поэтому, переведясь затем в Останкинскую межрайонную прокуратуру, я была готова к любому судебному процессу. И, соответственно, вновь «оказалась» на «УСО» (уголовно-судебном надзоре). Да, через какое-то время попробовала себя и на надзоре за учетно-регистрационной дисциплиной органов внутренних дел, и на надзоре за органами следствия и дознания, но, вернулась в стены суда, как в эпицентр событий профессиональной жизни любого юриста.</p>
            <p class="about-opyt-text">В той или иной степени, мной было изучено более двух тысяч уголовных дел. Понимаю, что нигде и никогда я не смогла бы получить такой опыт. С гордостью могу сказать: я – профессиональный юрист.</p>
            <p class="about-opyt-text">В силу некоторых внутренних убеждений, не получаю статуса адвоката. Не испытываю потребности в реальном участии в качестве адвоката по уголовным делам, реализую свою потребность в «адреналине» на «цветочном поле» гражданского процесса, бывает, берусь и за интересные дела частного обвинения.</p>
            <p class="about-opyt-text">Однако, как и врач, не могу отказывать в профессиональной помощи, тем более, по уголовным делам, когда правильный совет и рекомендация, зачастую, меняют всю траекторию развития ситуации. Да, я не могу «отслеживать» ход предварительного следствия (если ко мне не обратился лично подозреваемый, он же обвиняемый, он же подсудимый) и часть судебного в силу наличия такого понятия как «тайна следствия», но, увы, понимаю, что, если дело уже возбуждено, лишь кропотливое «высиживание» адвокатом в кабинетах и залах своей позиции сможет переломить ход следствия, ну, или «вмешательство свыше». Вероятнее всего, дело окажется в суде, где после исследования доказательств стороны обвинения мои аналитические навыки будут более чем просто полезны. И еще, мне особенно нравится чувство осознания того, что путем анализа можно «обыграть» или увидеть то, что не увидели следователи, прокуроры, адвокаты.</p>
        </div>
    </section>
</template>

<script>
export default {

}
</script>

<style>
.about-opyt-content{
    padding:1rem 10vw
}
.about-opyt-img{
    float: left;
    max-height: 300px;
    padding:0 1rem 0 0;
    max-width: 80vw;
}
.about-opyt-text{
    margin: 3px 0;
    font-size: clamp(1rem,2.7vh,2rem);
    text-align: justify;
    text-indent: 1rem;
}
@media(max-width:960px){
    .about-opyt-img{
        margin:1rem auto
    }
}
</style>