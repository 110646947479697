<template>
    <div class="about-page">
        <div class="about-page-baner">
      <div class="about-page-baner__title">
        <span class="about-baner__name">Рябова Евгения</span>
        <span class="about-baner__ur">Юрист</span>
        <span class="about-baner__pd">Аналитик по уголовным делам</span>       
      </div>
    </div>
    <ZnanieBlock/>
    <OpytBlok/>
    <div class="int-blok">
      <div class="about-section-baner">
        <div class="about-section-baner__title" id="intuitsia">ИНТУИЦИЯ</div>
      </div>
      <p class="about-int-text">Да-да, после дипломов, опыта, моим самым верным помощником остается интуиция. Будучи скептиком, трудно в это поверить. Но! Вы знали, что интуиция – это уже давно научное понятие? Я узнала об этом только лет десять назад, когда решила понять, что же за «озарение», или как сейчас модно называть «инсайт», помогает мне сразу открывать дело на самом «проблемном» листе. Или как получается так, что, еще не зная в чем суть дела меня уже терзают «смутные сомнения». О да, вопрос о том, «предчувствие» это или моделирование, оставлю для специалистов из других сфер, но для себя, как юриста и исследователя, я поняла одно – глупо игнорировать, надо учиться применять и развивать. По результатам могу сделать однозначный вывод: интуиция – неотъемлемый вид познания любого явления, включая правовое. Даже больше, уверена, что, если бы не врожденная интуиция, я бы вряд ли смогла получить все имеющиеся у меня знания и опыт.</p>
    </div>
  </div>
</template>

<script>
import ZnanieBlock from './ZnanieBlock.vue'
import OpytBlok from './OpytBlok.vue'
export default {
    components:{ZnanieBlock,OpytBlok}
}
</script>

<style>
.about-page{
  background:url(@/../public/img/tekstura-bumagi.png) center/cover no-repeat;
}
.about-page-baner{
  height: 200px;
  background:linear-gradient(180deg, #000000b2 30%,#444444b1), url(@/../public/img/belii-kirpichni.jpg) no-repeat;
  background-size: cover;
  background-position: top;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1vh 5vw;
  text-align: center;
}
.about-page-baner__title{
  color: var(--light-text);
  display: flex;
  flex-direction: column;
}
.about-baner__name {
  font-size: clamp(2rem,12vh,3rem);
  font-family: var(--title-font);
  font-weight: 500;
}
.about-baner__pd {
  font-size: clamp(1rem,3.5vh,2rem);
  font-family: var(--menu-font);
}
.about-baner__ur {
  font-size: clamp(1rem,4vh,2rem);
  font-family: var(--menu-font);
}
.about-section-baner {
    background:radial-gradient( #e1e1e1c7,#c6c6c6b9,#9b9b9bc6), url(@/../public/img/belii-kirpichni.jpg);
    height: 10vh;
    min-height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 2rem;
}
.about-section-baner__title {
    font-size: clamp(2rem,4vh,3rem);
    font-family: var(--title-font);
    font-weight: 700;
}
.about-int-text{
    padding: 2rem 10%;
    font-size: clamp(1rem,2.7vh,2rem);
    text-align: justify;
    text-indent: 1rem;
    margin: 0;
}
@media (prefers-color-scheme: dark){
  .about-page{
   background:linear-gradient(30deg, #000000c7,#515151b9,#060606c6), url(@/../public/img/tekstura-bumagi.png) top/cover no-repeat;
   color: var(--light-text);
}
.about-section-baner {
    background:radial-gradient( #575757c7,#434343b9,#1c1c1cc6), url(@/../public/img/belii-kirpichni.jpg);
}
}
@media(max-width:960px){
  .about-baner__name {
  font-size: clamp(1rem,8vh,2rem);
  font-family: var(--title-font);
  font-weight: 500;
}
.about-baner__pd {
  font-size: clamp(1rem,3vh,2rem);
  font-family: var(--menu-font);
}
.about-baner__ur {
  font-size: clamp(1rem,3.5vh,2rem);
  font-family: var(--menu-font);
}
}
</style>