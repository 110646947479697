<template>
  <div class="usluga-content-2">
    <h1 class="usluga-name-2">{{usluga.name}}</h1>
    <span class="usluga-subtitle-2">{{usluga.subtitle}}</span>
    <p class="usluga-text-2" v-for="item in usluga.text" :key="item">{{item}}</p>
    <div class="usluga-dist-2">{{usluga.dist}}</div>
  </div>
</template>

<script>
export default {
data(){
    return{
        usluga:this.$store.state.uslugi.usluga2
    }
 }
}
</script>

<style>
.usluga-content-2{
    margin: 0;
    padding: 2rem 10%;
    font-size: clamp(1rem, 3vh, 1.5rem);
    text-align: justify;
}
.usluga-name-2{
    margin: 0;
    padding: 2rem 0;
    font-family: var(--title-font);
    text-align: left;
}
.usluga-subtitle-2{
    display: block;
    padding:0 0 5vh 0;
    font-style: italic;
    text-align: right;
}
.usluga-dist-2{
    font-style: italic;
    padding-top: 2vh;
    font-size: clamp(1rem, 2vh, 1.5rem);
}
.usluga-text-2{
    margin: 0;
    padding: 2px 0;
    text-indent: 15px;
}
@media (max-width: 960px) {
.usluga-content-2{
    margin: 0;
    padding: 5px;
    font-size: clamp(1rem, 3vh, 2rem);
}
.usluga-name-2{
    margin: 0;
    padding: 2rem 10px;
    font-family: var(--title-font);
    font-size: clamp(1rem, 5vh, 3rem);
}
}
</style>