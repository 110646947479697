<template>
  <div class="dopros-page">
    <div class="dopros-page-baner">
        <div class="dopros-page-baner__title">Индивидуальные тематические тренинги по подготовке к допросу</div>
        <div class="dopros-page-baner__content">
            <span>Мои тренинги, методы, способы подачи учебной и практической информации, не только помогут улучшить навыки ведения допроса, но и позволят чувствовать себя увереннее и контролировать ситуацию во время любого интервью.</span>
            <a href="#dopros-page-content" class="baner-content__a"><span>Подробнее</span></a>
        </div>
        <div class="dopros-page-baner__form"><MainForm/></div>
    </div>
    <article class="dopros-page-content" id="dopros-page-content">
        <p class="dopros-page-content__start">Умение задавать правильные вопросы, умение допрашивать, умение грамотно отвечать и не отвечать на вопросы – неотъемлемые навыки профессионала любой сферы. Стратегически необходимы: юристу, продажнику, менеджеру, риелтору, блогеру, журналисту, управленцу, hr-специалисту, … (перечень открытый и в эпоху развития информационных технологий, пополняется с каждым днем).</p>
        <h3 class="dopros-page-content__h3">Как минимум пять причин, почему стоит научиться допрашивать:</h3>
        <ol class="dopros-page-content__ul">
            <li class="dopros-page-content__li">Получение полной информации: задавая уточняющие вопросы, можно получить дополнительную информацию, которую невозможно получить иначе. Особенно полезно в работе и бизнесе, когда нужно принимать важные решения в условиях ограниченности информационных ресурсов и времени.</li>
            <li class="dopros-page-content__li">Развитие критического мышления: при допросе необходимо понимать, какие вопросы следует задавать, как их структурировать и формулировать. Это помогает развивать критическое и логическое мышление.</li>
            <li class="dopros-page-content__li">Облегчение коммуникации: поможет установить доверительные отношения с коллегами, клиентами и партнерами, улучшить коммуникацию и решить критически важные проблемы. Кроме того, можно улучить управленческие навыки: способность задавать вопросы и слушать ответы - это важный навык для управления проектами и группами.</li>
            <li class="dopros-page-content__li">Повышение личного развития: умение задавать правильные вопросы и умение допрашивать могут помочь в повседневной жизни, в том числе при общении с друзьями и близкими. Эти навыки могут способствовать улучшению наших отношений и повышению нашей самооценки. </li>
            <li class="dopros-page-content__li">Зная, как проводится допрос/опрос, можно подготовить защитную тактику и не дать поставить себя в сложную ситуацию, когда собеседник использует приемы перекрёстного допроса. Можно определить реальные цель и намерения оппонента.</li>
        </ol>
        <p class="dopros-page-content__p">Так как цели обучения и области применения навыков в обозначенной сфере весьма широки, а предлагаемый тренинг адаптируется под конкретные запросы клиента, перечислю наиболее востребованные темы:</p>
        <ul class="dopros-page-content__ul">
            <li class="dopros-page-content__li">ведение судебного допроса (имеет отличия);</li>
            <li class="dopros-page-content__li">перекрёстный допрос (классика и современность любого состязательного процесса); </li>
            <li class="dopros-page-content__li">игровой допрос (в целях подготовки к даче показаний – допрошу с пристрастием, либо побуду допрашиваемой, исправлю ошибки, дам рекомендации);</li>
            <li class="dopros-page-content__li">игровое интервью/собеседование;</li>
            <li class="dopros-page-content__li"> дача показаний/объяснений в правоохранительных, судебных органах, налоговой инспекции и пр.; </li>
            <li class="dopros-page-content__li">применение тактики перекрестного допроса в переговорах;
  повышение квалификации при подготовке к судебному допросу (для опытных, но еще неуверенных в себе или желающих «разогреться» судебных представителей, защитников и юристов).</li>
        </ul>
        <p class="dopros-page-content__p">Можно выбрать одну или несколько из предлагаемых тем, а можно предложить свою и я в кратчайшие сроки отвечу, смогу ли оказать компетентную помочь именно по конкретному запросу.</p>
        <h3 class="dopros-page-content__h3">Как минимум три причины почему я…</h3>
        <ol class="dopros-page-content__ul">
            <li class="dopros-page-content__li">Приняла участие в 2000 судебных заседаний в «топовых» судах г. Москвы в качестве государственного обвинителя. Я видела и знаю работу уголовного розыска, дознания, следствия, обвинения, защиты, суда.</li>
            <li class="dopros-page-content__li">Практикую в качестве судебного представителя более 20 лет.</li>
            <li class="dopros-page-content__li">Мой преподавательский стаж на юрфаках различных ВУЗов - более 6 лет. Я разработала и реализовала более 50 программ профессиональной подготовки, тренингов, индивидуальной подготовки.</li>
        </ol>
        <hr>
        <span class="dopros-price">Стоимость одного сеанса (занятия) продолжительностью не более 90 минут – 4 500 рублей.</span>
        <p class="dopros-page-content__p italic-text">Если вы хотите получить более подробную информацию о моем тренинге по подготовке к допросу, оставьте свой контакт и я свяжусь с вами в ближайшее время. Уверена, что мой тренинг подготовки к допросу сможет жизненно помочь вам в любой ситуации! </p>
    </article>
  </div>
</template>

<script>
import MainForm from '../mainscreen/MainForm.vue'
export default {
components:{MainForm}
}
</script>

<style>
.dopros-page-baner{
    background:linear-gradient(180deg,rgb(20,20,20)10%, rgba(0, 0, 0, 0.831) 30%,#000000b1,rgb(20,20,20)90%), url(@/../public/img/dopros.webp) top/cover no-repeat ;
    height: 90vh;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: 1rem 10% 0;
}
.dopros-page-baner__title {
    color: var(--light-text);
    flex-basis: 100%;
    font-family: var(--title-font);
    font-size: clamp(3rem, 9vh, 5rem);
    color: var(--light-text);
    font-weight: 300;
}
.dopros-page-baner__content {
    flex-basis: 70%;
    display: flex;
    flex-direction: column;
    font-size: clamp(1rem, 4vh, 3rem);
    font-weight: 200;
    box-sizing: border-box;
    padding: 0 10% 1rem 0;
    color: var(--light-text);
    font-family: var(--title-font);
    font-weight: 400;
}
.dopros-page-baner__content span{
    padding: 0 10% 1rem 0;
}
.baner-content__a{
     color: var(--light-text);
     font-size: 4vh;
     border: 1px solid whitesmoke;
     width: fit-content;
     padding:.5rem 1rem;
     font-family: var(--menu-font);
}
.baner-content__a:hover{
    color:rgb(196, 208, 218);
}
.dopros-page-baner__form {
    flex-basis: 30%;
}
.dopros-page-content{
    padding: 2rem 10%;
    text-align: justify;
    background:url(@/../public/img/tekstura-bumagi.png) center/cover no-repeat;
    font-size: large;
}
.dopros-page-content__start{
    font-size: larger;
}
.dopros-price{
    font-weight: 500;
    font-size: clamp(1rem,3vh,2rem);
}

@media(max-width:960px){
    .dopros-page-baner{
        padding: 1rem;
        height: fit-content;
    }
    .dopros-page-baner__title {
        font-size: clamp(2rem,4vh,3rem);
    }
    .dopros-page-baner__content{
        font-size: clamp(1rem,2vh,2rem);
        flex-basis: 100%;
        min-height: 40vh;
    }
    .dopros-page-baner__content span {
    padding: 1rem 0;
    }
    .baner-content__a{
        margin: auto;
    }
    .dopros-page-baner__form {
    flex-basis: 100%;
    }
    .dopros-page-content{
        text-align: left;
        padding:5px 15px;
    }
}
@media (prefers-color-scheme: dark) {
    .dopros-page-content{
    background:linear-gradient(30deg, rgba(53, 53, 53, 0.9),rgba(33, 33, 33, 0.9),rgba(60, 60, 60, 0.9)), url(@/../public/img/tekstura-bumagi.png) no-repeat;
    color: var(--light-text);
    }
    
}
</style>