<template>
  <TrenPage/>
</template>

<script>
import TrenPage from '../components/tren/TrenPage.vue'
export default {
  components:{TrenPage}

}
</script>

<style>

</style>
