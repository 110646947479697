import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import UslugiView from '../views/UslugiView.vue'
import TrenView from '../views/TrenView.vue'
import CasesView from '../views/CasesView.vue'
import AboutView from '../views/AboutView.vue'
import DoprosTreningView from '../views/DoprosTreningView.vue'
import OplataPageView from '../views/OplataPageView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/about',
    name: 'about',
    component: AboutView,
  },
  {
    path: '/about#opyt',
    name:'opyt',
  },
  {
    path: '/uslugi',
    name: 'uslugi',
    component: UslugiView,
    children:[
      {
        path:'/:nameProps',
        component:UslugiView,
        props:true
      }
    ]
  },
  {
    path: '/trenings',
    name: 'trenings',
    component: TrenView, 
  },
  {
    path: '/cases',
    name: 'cases',
    component: CasesView, 
  },
  {
    path:'/dopros',
    name:'dopros',
    component:DoprosTreningView
  },
  {
    path:'/oplata',
    name:'oplata',
    component:OplataPageView
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior(to,from,savedPosition) {
    if (to.hash) {
        return {selector: to.hash}
    }
    else if(from){
      return savedPosition
    }
  },
  routes
})
export default router
