<template>
  <div class="main-screen">
    <div class="main-baner">
      <menu class="main-baner-menu">
          <a href="/about#znanie">Знания</a>
          <a href="/about#opyt">Опыт</a>
          <a href="/about#intuitsia">Интуиция</a>
      </menu>
      <div class="main-baner-content">
        <div class="baner-content-left">
          <div class="baner-title">АНАЛИТИК ПО УГОЛОВНЫМ ДЕЛАМ</div>
          <div class="main-baner-subtitle">Евгения Рябова<span class="star-inn" title="*Является плательщиком налога на профессиональный доход (ИНН 772857290413)">*</span></div>
        </div>
        <div class="baner-content-right"><main-form/></div>
      </div>
    </div>
  </div>
</template>

<script>
import MainForm from './MainForm.vue'
export default {
components:{
    MainForm
}
}
</script>

<style>
.main-screen{
    height: 95vh;
    min-height: 500px;
    background: url(@/../public/img/belii-kirpichni.jpg);
    background-size: cover;
    background-position: center -50px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-end;
}
.main-baner {
  background-image: url(@/../public/img/mcb-light.png);
  background-repeat: no-repeat;
  background-size: cover;
  width: 80%;
  height: 70%;
}
.main-baner-menu {
  height: 15%;
  min-height: 30px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding:16px 32px;
}
.main-baner-menu a{
  font-size: clamp(24px,4vh,2rem);
  color: var(--dark-text);
  text-decoration: none;
  font-family: var(--menu-font);
}
.main-baner-content {
  display: flex;
  font-family: var(--title-font);
}
.baner-content-left {
  flex-basis: 70%;
  padding:2rem 5%;
  align-self: flex-start;
}
.baner-content-right {
  flex-basis: 30%;
  align-self: flex-end;
}
.baner-title {
  font-size: clamp(24px,10vh,4rem);
  padding: 0;
  margin: 0;
}
.main-baner-subtitle {
  font-size: clamp(24px,3vh,1.5rem);
}
.star-inn{
  cursor: pointer;
}
@media(max-width:960px){
  .main-screen{
    height: fit-content;
    min-height: 85vh;
  }
  .main-baner{
    width: 100%;
    background-size: contain;
  }
  .main-baner-content {
    flex-direction: column;
  }
  .main-baner-menu {
    height: 5%;
    min-height: 20px;
    margin: 0;
    padding: 1rem;
    justify-content: space-between;
  }
  .baner-title{
    font-size: clamp(24px,4vh,3rem);
  }
  .baner-content-left{
    padding: 1rem;
  } 
}
@media (prefers-color-scheme: dark) {
    .main-screen{
    background:linear-gradient(90deg,rgba(0, 0, 0, 0.35),rgba(0, 0, 0, 0.692)), url(@/../public/img/belii-kirpichni.jpg);
    background-position: center -50px;
  }
  .main-baner {
  background-image: url(@/../public/img/mcb-dark.png);
  color: var(--light-text);
}
  .main-baner-menu a{
    color: var(--light-text);
  }
}
</style>