<template>
  <div class="footer-contacts">
    <div class="footer-menu-icon" >
      <a class="footer-menu-icon__a"  v-for="icon in this.$store.state.contact" :key="icon" :href="icon.href">
        <i :class="icon.icon" :title="icon.name" ></i>
      </a>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>
.footer-contacts{
  padding: 1rem;
}
.footer-menu-icon{
  display: flex;
  font-size: 2rem;
  justify-content: center;
  flex-wrap: wrap;
}
.footer-menu-icon i{
  padding: 1rem;
  min-width: 70px;
  color: var(--light-text);
  opacity: .7;
  text-align: center;
}
.footer-menu-icon i:hover{
  opacity: 1;
  transition: opacity .5s;
}
.footer-menu-phone{
  font-size: clamp(1rem,6vw,2rem);
}
</style>