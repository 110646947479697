<template>
  <transition name="menu">
    <div class="mobile-menu">
    <i class="close-menu fa fa-close" @click="$emit('closeMenu')"></i>
    <div class="menu-logo">UDELA.RU</div>
    <router-link to="/about" class="main-menu-name" @click="$emit('closeMenu')">
      <h2 class="main-menu-name__title">Рябова Евгения</h2>
      <div class="main-menu-name__ur">Юрист</div>
      <div class="main-menu-name__aud">Аналитик по уголовным делам</div>
    </router-link>
    <nav class="main-menu"  @click="$emit('closeMenu')" >
      <router-link to="uslugi" >Услуги</router-link>
      <router-link to="trenings" >Тренинги</router-link>
      <router-link to="cases" >Кейсы</router-link>
    </nav>
    <div class="menu-icon" >
      <a class="menu-icon__a"  v-for="icon in this.$store.state.contact" :key="icon" :href="icon.href">
        <i :class="icon.icon" :title="icon.name" ></i>
      </a>
    </div>
    <a class="menu-phone" href="tel:79787045157">+7 (978) 704-51-57</a>
  </div>
  </transition>
</template>

<script>
export default {
name:'MobileMenu',   
}
</script>

<style lang="scss" scoped>
.menu-enter-active, .menu-leave-active {
  transition: opacity .5s ease;
}
.menu-enter-from, .menu-leave-to {
  opacity: 0;
}

.mobile-menu{
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  background: black;
  color: whitesmoke;
  padding: 1rem;
  box-sizing: border-box;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  text-align: center;
  font-family: var(--menu-font);
  z-index: 10;
}
.mobile-menu a {
  color: var(--light-text);
}
.close-menu{
  font-size: 2rem;
  color: whitesmoke;
  text-align: right;
  width: 100%;
  opacity: .7;
  transition: all 1s;
}
.close-menu:hover{
  opacity: 1;
  text-shadow: 1px 3px 30px rgba(224, 14, 14, 0.395);
}
.menu-logo {
  font-family: var(--logo-font);
  font-size: clamp(1rem, 5vw, 3rem);
  text-align: center;
}
.main-menu{
  align-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: clamp(1rem, 3vw, 2rem);
  padding: 5px;
}
.main-menu span{
  font-size: 1.8rem;
  padding: 1.5rem;
}
.main-menu-name__title{
  margin: 0;
  font-weight: 400;
  font-family: var(--title-font);
}
.menu-icon{
  display: flex;
  font-size: 2rem;
  justify-content: center;
  flex-wrap: wrap;
}
.menu-icon i{
  padding: 1rem;
  min-width: 70px;
}
.menu-phone{
  font-size: clamp(1rem,6vw,2rem);
}
@media(max-width:960px){
  .main-menu a{
    font-size: clamp(1rem,7vw,2rem);
    padding: 10px 0;
  }
  .menu-logo{
    font-size: clamp(2rem, 5vh,5rem);
  }
}
</style>