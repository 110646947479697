export function sendForm(el){
    
    let name = el.form.querySelector('.name').value;
    let tel = el.form.querySelector('.tel').value;

    async function sendTelegram(form) {
         await fetch("https://udela.ru/telegram.php", {
          method: "POST",
          body: new FormData(form),
        });
    }

    if (tel && name && name.length < 25) {
        sendTelegram(el.form);
    } else {
      alert('Заполните поля формы')
    }
    el.form.reset();
    }