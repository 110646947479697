<template>
  <div class="tren-page">
    <div class="tren-page-baner">
      <div class="tren-page-baner__title">ТРЕНИНГИ</div>
    </div>
    <main class="tren-page-content">
    <h2 class="tren-page-title">
        Индивидуальные, групповые тренинги и подготовка для: юристов, адвокатов, студентов и пр.
    </h2>
    <p class="tren-page-text">
        Индивидуальная подготовка для всех участников гражданского и уголовного процесса: очевидцев и свидетелей, подозреваемых (обвиняемых, подсудимых), юристов, адвокатов (защитников) и всех заинтересованных в приобретении новых навыков или подготовки к допросу (даче объяснений). Актуально в современных условиях - подготовка к даче устных объяснений (пояснений) в рамках дисциплинарной или служебной проверки.
    </p>
    <p class="tren-page-text">
        Услугу оказываю лично для физических и юридических лиц. Являюсь плательщиком налога на профессиональный доход. Документы о прохождении обучения (кроме договора и чека) не выдаются. Согласно ст. 91 Федерального закона от 29.12.2012 № 273-ФЗ «Об образовании в Российской Федерации» оказываемая услуга не подлежит лицензированию.
    </p>
    <div class="tren-page-list">
        <h3 class="tren-page-list__h2">Наиболее востребованные программы (индивидуальные и групповые) тренингов и деловых игр:</h3>
        <ul class="tren-page-list__ul">
            <li class="tren-page-list__li" v-for="li in tren" :key="li"><a :href="li.href">{{li.name}}</a></li>
        </ul>
    </div>
    <p class="tren-page-text italic-text">
        График согласовывается с заказчиком, при необходимости возможен выезд к заказчику.
    </p>
    <div class="tren-page-grafik">
        <h3 class="tren-page-grafik__h3">Программа включает:</h3>
        <ul class="tren-page-grafik__ul">
            <li class="tren-page-grafik__li"> теория – 10-30%;</li>
            <li class="tren-page-grafik__li">интерактив (практика, кейсы, разбор, проектирование и пр.) – 70-90%;</li>
            <li class="tren-page-grafik__li">раздаточный материал: методички, библиография по учебной и научной литературе, чек-листы (например, по перекрёстному допросу, по речи в прениях, по анализу уголовного дела и пр.).</li>
        </ul>
        <span>Стоимость: от 1,5 до 20 часов – от 5000 до 60000₽.</span>
        <p class="tren-page-text italic-text">
            Разработаю программу и методику под требования заказчика.
        </p>
        <div class="tren-page-pochemu">
            <h3 class="tren-page-pochemu__h3">Три причины почему я…</h3>
            <div class="pochemu-block-wrap">
                <div class="tren-page-pochemu__block">
                    <div class="pochemu-block__top">2000+</div>
                    <div class="pochemu-block__bottom">Я приняла участие в 2000 судебных заседаний в «топовых» судах г.Москвы в качестве гособвинителя. Я видела и знаю работу обвинения, защиты, суда.</div>
                 </div>
                <div class="tren-page-pochemu__block">
                    <div class="pochemu-block__top">20+</div>
                    <div class="pochemu-block__bottom">Практикую в качестве судебного представителя более 20 лет.</div>
                </div>
                <div class="tren-page-pochemu__block">
                    <div class="pochemu-block__top">50+</div>
                    <div class="pochemu-block__bottom">Мой преподавательский стаж на юрфаке различных ВУЗов - более 6 лет. Я разработала и реализовала более 50 программ профессиональной подготовки, тренингов, индивидуальной подготовки.</div>
                </div>
            </div>
        </div>
    </div>
    </main>
  </div>
</template>

<script>
export default {
data(){
    return{
        tren:this.$store.state.trening
    }
}
}
</script>

<style>
.tren-page-content{
    padding: 3% 10%;
    text-align: justify;
}
.tren-page-baner{
  height: 200px;
  background:linear-gradient(180deg, #000000b2 30%,#444444b1), url(@/../public/img/belii-kirpichni.jpg) no-repeat;
  background-size: cover;
  background-position: top;
  display: flex;
  align-items: center;
  justify-content: center;
}
.tren-page-baner__title{
  font-size: clamp(24px,10vh,5rem);
  font-family: var(--title-font);
  color: var(--light-text);
  font-weight: 700;
  letter-spacing: 2px;
  text-shadow: 1px 1px 5px rgba(0, 0, 0, 1);
}
.tren-page-content {
    font-size: clamp(1rem,3vh,1.5rem);
    background:url(@/../public/img/tekstura-bumagi.png) center/cover no-repeat;
}
.tren-page-title {
    font-family: var(--title-font);
    font-size: clamp(1rem,3.5vh,3rem);
    text-align: center;
}
.tren-page-list__h2 {
    font-family: var(--title-font);
    font-size: clamp(1rem,4vh,2rem);
    text-align: center;
    padding: 2rem 0 0;
}
.tren-page-list__ul{
    padding: 2rem 0;
}
.tren-page-list__li {
    font-size: clamp(1rem,4vh,2rem);
    font-weight: 200;
    margin:15px;
    width: 20vw;
    min-width: fit-content;
    box-sizing: border-box;
    font-family: var(--menu-font);
}
.tren-page-grafik__h3 {
    font-family: var(--title-font);
    font-size: clamp(1rem,4vh,2rem);
    text-align: center;
    padding: 2rem 0 0;
}
.tren-page-grafik span{
    font-weight: 500;
}
.tren-page-grafik__li {
    list-style:circle;
}
.tren-page-grafik span{
    font-weight: 400;
    font-size: larg;
}
.tren-page-pochemu {
    display: flex;
    flex-wrap: wrap;
}
.tren-page-pochemu__h3 {
    font-family: var(--title-font);
    font-size: clamp(1rem,4vh,3rem);
    text-align: center;
    padding: 2rem 0 0;
    flex-basis: 100%;
}
.pochemu-block-wrap{
    display: flex;
    justify-content: space-around;
}
.tren-page-pochemu__block {
    text-align: center;
    flex-basis: 30%;
}
.pochemu-block__top {
    font-size: clamp(2rem,7vh,3rem);
    font-family: var(--title-font);
    font-weight: 700;
    padding: 1rem 0;
}
@media(max-width:800px){
    .tren-page-content{
        text-align: left;
    }
    .tren-page-baner__title{
        font-size: clamp(1rem, 7vh, 4rem);
    }
    .pochemu-block-wrap{
        flex-direction: column;
        padding-bottom: 2rem;
    }
}
@media (prefers-color-scheme: dark){
  .tren-page-content{
    background:linear-gradient(30deg, #000000c7,#515151b9,#060606c6), url(@/../public/img/tekstura-bumagi.png) no-repeat;
    color: var(--light-text);
}
.tren-page-content a{
    color: var(--light-text);
    text-align: left;
}
}
</style>