<template>
  <div class="usluga-content-1">
    <h1 class="usluga-name-1">{{usluga.name}}</h1>
    <ul class="usluga-list-1" >
        <li class="usluga-list__item-1" v-for="li in usluga.list" :key="li">{{li}}</li>
    </ul>
    <p class="usluga-text-1" v-for="item in usluga.text" :key="item">{{item}}</p>
    <div class="usluga-dist-1">{{usluga.dist}}</div>
  </div>
</template>

<script>
export default {
 data(){
    return{
        usluga:this.$store.state.uslugi.usluga1
    }
 }
}
</script>

<style>
.usluga-content-1{
    margin: 0;
    padding: 2rem 10%;
    font-size: clamp(1rem, 3vh, 1.5rem);
    text-align: justify;
}
.usluga-name-1{
    margin: 0;
    padding: 2rem 0;
    font-family: var(--title-font);
}
.usluga-dist-1{
    font-style: italic;
    padding-top: 2vh;
    font-size: clamp(1rem, 2vh, 1.5rem);
}
.usluga-text-1{
    margin: 0;
    padding: 2px 0;
    text-indent: 15px;
}
@media (max-width: 960px) {
.usluga-content-1{
    margin: 0;
    padding: 5px;
    font-size: clamp(1rem, 3vh, 2rem);
}
.usluga-name-1{
    margin: 0;
    padding: 2rem 10px;
    font-family: var(--title-font);
    font-size: clamp(1rem, 5vh, 3rem);
}
}
</style>