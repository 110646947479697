<template>
    <div v-show="scrolDisplayComp" class="scroll-bar animate__animated animate__fadeIn animate__fadeOut">
      <div class="scroll-bar__item" id="show-scroll"></div>
    </div>
</template>

<script>
export default {
data(){   
    return {
      itemHeight:``,
      scrollBarDisplay:false
    }
  },
  computed:{
    scrolDisplayComp(){
      return this.scrollBarDisplay
    }
  },
  methods:{
    scrollView(){    
      this.scrollBarDisplay=true
      this.itemHeight =document.body.scrollHeight / window.scrollY
      document.getElementById('show-scroll').style.height = `${100 / this.itemHeight * 1.4}%`
    },
    scrollViewHide(){
      this.scrollBarDisplay=false      
    }
  },
  mounted(){
      window.addEventListener('scroll', this.scrollView)
      window.addEventListener('scrollend',this.scrollViewHide)
    }
  }
</script>

<style>
.animate__animated.animate__fadeIn{
animation-duration: 1200ms;
}
.scroll-bar{
    position: fixed;
    opacity: .5;
    background: rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    width: 10px;
    right: 2vw;
    top: 20%;
    bottom: 20%;
    overflow: hidden;   
}
.scroll-bar__item{
    background: rgba(0, 0, 0, 0.7);
}
</style>