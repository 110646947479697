<template>
  <div class="oplata-wrap">
    <h1>РЕКВИЗИТЫ ДЛЯ ОПЛАТЫ</h1>
    <h2 class="oplata-wrap-h2">Принимаются денежные переводы (СберБанк, СПБ, РНКБ) по номеру телефона: +7 978 704 51 57.</h2>
    <div class="oplata-cards">
      <div class="oplata-bank-card">
        <h3>Перевод на карту</h3>
        <img class="oplata-img" src="img/qr-code.png" alt="">
      </div>
      <div class="oplata-sbp">
        <h3>Быстрым платежом</h3>
        <img class="oplata-img" src="img/sbp.png" alt="">
        <span>ОПЛАТА ПО НОМЕРУ ТЕЛЕФОНА</span>
        <span> <b>+7 (978) 704 51 57</b> (РНКБ Банк)</span>
      </div>
    </div>
    <div class="oplata-span">
      <span>Получатель платежей: Рябова Евгения Игоревна</span>
      <span>номер карты СберБанк 2202 2067 4810 3582</span>
      <span>номер карты РНКБ 2200 0202 2934 7838</span>
    </div>
  </div>
</template>

<script>
export default {
  
}
</script>

<style>
.oplata-wrap{
  display: flex;
  flex-direction: column;
  padding: 5%;
  text-align: center;
}
.oplata-wrap-h2{
  font-size: 1.2rem;
  font-weight: 300;
}
.oplata-wrap h1{
  padding: 1.5rem;
  font-size: 1.3rem;
  font-weight: 400;
}
.oplata-cards{
  display: flex;
  width: 80%;
  justify-content: space-around;
  padding:1rem 10%;
}
.oplata-cards div{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.oplata-cards h3{
  font-weight: 200;
  padding: 1rem;
  text-align: center;
}
.oplata-img {
  max-width: 200px;
  text-align: center;
}
.oplata-span{
  padding: 2rem;
  display: flex;
  flex-direction: column;
  text-align: center;
}
.oplata-wrap span{
  font-size: 1.2rem;
  font-weight: 200;
  line-height: 1.5;
}

@media (max-width:640px) {
  .oplata-cards{
    flex-direction: column;
  }
  .oplata-cards div{
    margin: 10px;
}

}

@media (prefers-color-scheme: dark){
  .oplata-wrap{
    background:linear-gradient(30deg, #000000c7,#515151b9,#060606c6), url(@/../public/img/tekstura-bumagi.png) top/cover no-repeat;
  } 
}
</style>