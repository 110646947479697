<template>
  <div class="usluga-content-4">
    <h1 class="usluga-name-4">{{usluga.name}}</h1>
    <span class="usluga-subtitle">{{usluga.subtitle}}</span>
    <div class="usluga-shema-wrap"><UslugaChetShema/></div>
    <p class="usluga-text" v-for="text in usluga.text" :key="text">{{text}}</p>
    <span class="usluga-list-title">{{usluga.list.title}}</span>
    <ul class="usluga-list">
        <li class="isluga-list-li" v-for="li in usluga.list.item" :key="li">{{li}}</li>
    </ul>
    <p class="usluga-subtext">{{usluga.subText}}</p>
    <p class="usluga-dist">{{usluga.dist}}</p>
  </div>
</template>

<script>
import UslugaChetShema from '../Uslugi/UslugaChetShema.vue'
export default {
    components:{UslugaChetShema},
data(){
    return{
        usluga:this.$store.state.uslugi.usluga4
    }
 }
}
</script>

<style>
.usluga-content-4{
    margin: 0;
    padding: 2rem 10%;
    font-size: clamp(1rem, 3vh, 1.5rem);
    text-align: justify;
}
.usluga-name-4{
    margin: 0;
    padding: 2rem 0;
    font-family: var(--title-font);
}
.usluga-dist{
    font-style: italic;
    padding-top: 2vh;
    font-size: clamp(1rem, 2vh, 1.5rem);
}
.usluga-text{
    margin: 0;
    padding: 2px 0;
    text-indent: 15px;
}
.usluga-subtitle{
    display: block;
    padding:0 0 5vh 0;
    font-style: italic;
    text-align: right;
}
.usluga-shema-wrap{
    max-width: 600px;
    float: left;
    padding: 0 2rem 0 0;
}
.usluga-list-title{
    padding-top: 2rem;
    font-weight: 700;
    display: block;
}

@media (max-width: 960px) {
.usluga-content-4{
    margin: 0;
    padding: 5px;
    font-size: clamp(1rem, 3vh, 2rem);
}
.usluga-name-4{
    margin: 0;
    padding: 2rem 10px;
    font-family: var(--title-font);
    font-size: clamp(1rem, 6vh, 3rem);
}
.usluga-shema-wrap{
    padding: 0;
}
}
</style>