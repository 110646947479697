<template>
  <div class="usluga__article_4_shema">
          <div class="usluga-shema__flex-wrap">
            <h3 class="usluga-shema__title">Cтадии уголовного процесса, охватываемые запретом на придание гласности
              данных
              предварительного следствия
              (тайной следствия)
            </h3>
            <div class="usluga-shema__wrap">
              <div class="usluga-shema__title_1 margin-bottom-05">
                Сообщение о преступлении (событие) -
                доследственная проверка
              </div>
              <div class="usluga-shema__title_1">
                Досудебное производство
              </div>
              <div class="usluga-shema__right-border">
                <div class="usluga-shema__right-border__absolute">ТАЙНА СЛЕДСТВИЯ</div>
                <div class="usluga-shema__conteiner">
                  <ul class="uslugi-shema__ul-red padding-bottom-05">
                    <li>возбуждение уголовного дела, </li>
                    <li>предварительное расследование (дознание), </li>
                    <li>утверждение обвинительного заключения (акта, постановления);</li>
                    <li>направление в суд.</li>
                  </ul>
                  <div class="usluga-shema__title_1">
                    Судебное производство <span style="font-size: .9rem;">(при открытом судебном разбирательстве)</span>
                  </div>
                  <ul class="uslugi-shema__ul-red padding-bottom-05">
                    <li>предварительное слушание (при необходимости);</li>
                    <li>подготовительная часть судебного заседания;</li>
                  </ul>
                  <div style="text-align: center;"><span style="text-transform: uppercase; font-size: 1rem;">СУДЕБНОЕ
                      СЛЕДСТВИЕ</span><br><span></span>(в зависимости от того, какой порядок судебного разбирательства и
                    какой порядок исследования доказательств определен):
                  </div>
                  <ul class="uslugi-shema__ul-red">
                    <li>исследование доказательств стороны обвинения (допросы потерпевшего, свидетелей обвинения и пр.,
                      исследование письменных материалов, вещественных доказательств и т.д.);</li>
                  </ul>
                </div>
              </div>
              <ul style="max-width:90%;">
                <li>исследование доказательств стороны защиты (допросы свидетелей защиты, возможны допросы специалистов,
                  экспертов, исследование иных доказательств);</li>
                <li>допрос подсудимого;</li>
                <li>прения сторон;</li>
                <li>последнее слово подсудимого (очередность зависит от установленного порядка);</li>
                <li>оглашение приговора (решения).</li>
              </ul>
              <div class="usluga-shema__title_1 margin-top-05">
                ОБЖАЛОВАНИЯ...
              </div>
            </div>
          </div>
        </div>
</template>

<script>
export default {

}
</script>

<style>
/* Инфографика в блоке услуг */
.usluga__article_4_shema {
  display: flex;
  align-items: center;
  padding: 2rem;
  color: rgb(231, 236, 231);
  background-color: #27292F;
  margin-top: 1rem;
  font-size: .8rem;
}

.usluga-shema__title {
  font-size: 1rem;
  color: whitesmoke;
  font-weight: 400;
  text-transform: uppercase;
  padding: 0 0 1rem;
  text-align: center;
  border-bottom: 3px solid #E7613C;
  margin-bottom: 1.5rem;
}

.usluga-shema__wrap {
  border-left: #E7613C 5px solid;
}

.usluga-shema__conteiner {
  max-width: 90%;
}

.usluga-shema__title_1,
.usluga-shema__title_2 {
  text-transform: uppercase;
  padding-left: 1.5rem;
  position: relative;
}

.usluga-shema__title_1::before {
  content: '';
  border-left: 1rem #E7613C solid;
  left: 0;
  top: 0;
  bottom: 0;
  position: absolute;
}

.usluga-shema__right-border {
  position: relative;
}

.usluga-shema__right-border__absolute {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 2rem;
  color: #27292F;
  background-color: #E7613C;
  writing-mode: vertical-rl;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 1rem;
}

.usluga-shema__right-border::after {
  content: '';
  border-left: 1rem #E7613C solid;
  right: 0;
  top: 0;
  position: absolute;
  width: 2rem;
  height: 1rem;
}

.usluga-shema__right-border::before {
  content: '';
  border-left: 1rem #E7613C solid;
  right: 0;
  bottom: 0;
  position: absolute;
  width: 2rem;
  height: 1rem;
}

.uslugi-shema__ul-red {
  color: #E7613C;
}
</style>