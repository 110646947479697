<template>
  <div class="home">
    <main-screen/>
    <div class="serv-tren-wrap">
      <main-services/>
      <main-tren/>
    </div>
  </div>
</template>

<script>
import MainServices from '@/components/MainServices.vue'
import MainScreen from '../components/mainscreen/MainScreen.vue'
import MainTren from '@/components/MainTren.vue'


export default {
  name: 'HomeView',
  components: {
    MainScreen,
    MainServices,
    MainTren,
  }
}
</script>
<style>
.serv-tren-wrap{
  background:linear-gradient(30deg, #d5d5d5fc,#0000007a,#ffffff6e), url(@/../public/img/tekstura-bumagi.png) no-repeat;
  background-size: cover;
  backdrop-filter: blur(2px);
}
@media (prefers-color-scheme: dark) {
  .serv-tren-wrap{
  background:linear-gradient(30deg, #000000c7,#515151b9,#060606c6), url(@/../public/img/tekstura-bumagi.png) no-repeat;
}
}
</style>