<template>
  <div class="diplom-block">
    <div class="diplom-block-card" v-for="card in img" :key="card" @click="modalOpen(card)">
        <div class="diplom-block-card__hover"><span class="card-hover-title">{{card.title}}</span>{{card.name}}</div>
        <img class="diplom-block-card__img" :src="card.href" />
    </div>
    <div v-if="modal" class="modal-diplom" @click.self="modalClose()">
        <i class="fa fa-close modal-diplom-close" @click.self="modalClose()"></i>
        <img :src="this.modalImg.href" alt="">
    </div>
  </div>
</template>

<script>
export default {
    data(){
        return{
            modal:false,
            modalImg:'',
            img:{
                inst:{href:'img/diplom_big.jpg',title:'Диплом',name:'о высшем образовании'},
                kn:{href:'img/diplomkn_big.jpg',title:'Диплом',name:"кандидата наук"},
                dis:{href:'img/disert_big.jpg',title:'Дисертация'}
            }
        }
    },
    methods:{
        modalOpen(card){
            this.modalImg = card
            this.modal = true
            document.body.classList.add('overflow')
        },
        modalClose(){
            this.modal = false
            document.body.classList.remove('overflow')
        }
    }

}
</script>

<style>
.diplom-block{
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
}
.diplom-block-card{
    width: 23vw;
    height: 17vw;
    min-width: 200px;
    min-height: 200px;
    text-align: center;
    position: relative;
    box-shadow:1px 1px 5px black;
}
.diplom-block-card:hover .diplom-block-card__hover{
    opacity: 1;
}
.diplom-block-card__hover{
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    flex-direction: column;
    opacity: 0;
    transition: opacity 1s;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    font-size: clamp(1rem,2vh,2rem);
    color: var(--light-text);
}
.card-hover-title{
    font-family: var(--menu-text);
    font-size: clamp(2rem,5vh,3rem);
}
.diplom-block-card__img{
    height: 100%;
    width: 100%;
    object-position:right top;
    object-fit:cover;
}
.modal-diplom{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.9);
    z-index: 13;
    padding: 1%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
}
.modal-diplom img{
    object-fit: contain;
    max-height: 90%;
    max-width: 90%;
}
.modal-diplom-close{
    color: var(--light-text);
    font-size: 7vh;
    position: absolute;
    top:10px;
    right: 10px;
}
@media(max-width:960px){
    .diplom-block-card__img{
    margin: 10px ;
}
}
</style>