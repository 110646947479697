<template>
  <div class="uslugi-page">
    <div class="uslugi-page-baner">
      <div class="uslugi-page-baner__title">УСЛУГИ</div>
      <div class="uslugi-page-menu" @click="this.menuVisible = !this.menuVisible">
        <span class="uslugi-page-menu__title">Выбрать услугу<i class="fa fa-caret-down"></i></span>
        <transition name="menu-list">
          <div class="uslugi-page-menu__list" v-if="this.menuVisible" @click="this.menuVisible = !this.menuVisible">
            <span  v-for="(item,nam) in uslugi" :key="item" @click="uslugaName(nam),this.menuVisible = !this.menuVisible">{{item.name}}</span>
          </div>
        </transition>
      </div>
    </div>
    <div class="uslugi-page-content">
      <UslugaOdin v-if="nameUsluga=='usluga1'"/>
      <UslugaDva v-if="nameUsluga=='usluga2'"/>
      <UslugaTri v-if="nameUsluga=='usluga3'"/>
      <UslugaChet v-if="nameUsluga=='usluga4'"/>
    </div>
    <ul class="uslugi-page-bottom-menu">
      <li class="uslugi-page-bottom-menu__item" v-for="(item,num) in uslugi" :key="item" @click="uslugaName(num), topScroll()">{{item.name}}</li>
    </ul>
  </div>
</template>

<script>
import UslugaChet from './UslugaChet.vue'
import UslugaDva from './UslugaDva.vue'
import UslugaOdin from './UslugaOdin.vue'
import UslugaTri from './UslugaTri.vue'
export default {
components:{UslugaOdin,UslugaDva,UslugaTri,UslugaChet},
data(){
  return{
    uslugi:this.$store.state.uslugi,
    menuVisible:false,
    nameUsluga:this.$route.params.nameProps?this.$route.params.nameProps:'usluga1'
  }
},
methods:{
  uslugaName(nam){
    this.nameUsluga=nam
  },
  topScroll(){
    window.scrollTo(0,0)
  }
}
}
</script>

<style>
.uslugi-page-baner{
  height: 200px;
  background:linear-gradient(180deg, #000000b2 30%,#444444b1), url(@/../public/img/belii-kirpichni.jpg) no-repeat;
  background-size: cover;
  background-position: top;
  display: flex;
  flex-wrap: wrap;
}
.uslugi-page-baner__title{
  font-size: clamp(24px,10vh,5rem);
  font-family: var(--title-font);
  color: var(--light-text);
  font-weight: 700;
  letter-spacing: 2px;
  text-shadow: 1px 1px 5px rgba(0, 0, 0, 1);
  flex-basis: 100%;
  align-self: center;
  text-align: center;
}
.uslugi-page-menu{
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(2px) contrast(110%);
  padding: 1rem;
  color: var(--light-text);
  cursor: pointer;
  height: fit-content;
  align-self: flex-end;
  width: 100%;
  position: relative;
}
.uslugi-page-menu__title{
  text-transform: uppercase;
  font-size: clamp(1rem,4vh,3rem);
  font-family: var(--menu-title);
}
.uslugi-page-menu__title .fa{
  padding-left: 1rem;
}
.uslugi-page-menu__list{
  position: absolute;
  top:100%;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  padding: 1rem 0 0;
  background: rgba(0, 0, 0, 0.85);
  backdrop-filter: blur(3px) contrast(110%);
}
.uslugi-page-menu__list span{
  padding: 1rem 0;
  font-size: clamp(14px,3vh,2rem);
  cursor: pointer;
  width: fit-content;
}
.uslugi-page-menu__list span:not(:last-child){
  border-bottom: 1px rgba(245, 245, 245, 0.2) solid;
}

.uslugi-page-content{
  background:url(@/../public/img/tekstura-bumagi.png) center/cover no-repeat;
}
.uslugi-page-bottom-menu{
  display: flex;
  justify-content: space-around;
  padding: 1rem;
  background: black;
  margin: 0;
}
.uslugi-page-bottom-menu__item:not(:last-child)::after{
  content: '';
  position: absolute;
  top:20%;
  right:0;
  bottom: 20%;
  width: 1px;
  background: rgba(255, 255, 255, 0.2);
  box-sizing: border-box;
}
.uslugi-page-bottom-menu__item{
  width: fit-content;
  display: flex;
  align-items: center;
  text-align: center;
  padding: 10px;
  color: var(--light-text);
  position: relative;
  cursor: pointer;
}
@media(max-width:960px){
  .uslugi-page-content{
  padding: 0 5%;
}
 .uslugi-page-content p{
  text-align: left;
 }
 .uslugi-page-content h1{
  font-size: clamp(1.5rem,5vh,2rem);
  text-align: center;
 }
  .uslugi-page-bottom-menu{
  flex-wrap: wrap;
}
  .uslugi-page-bottom-menu__item{
    flex-basis: 40%;
    position: relative;
  }
  .uslugi-page-bottom-menu__item:not(:last-child)::after{
    content: none;
  }
  .uslugi-page-bottom-menu__item:nth-child(2n+1)::after{
  content: '';
  position: absolute;
  top:20%;
  right:0;
  bottom: 20%;
  width: 1px;
  background: rgba(255, 255, 255, 0.2);
  box-sizing: border-box;
  }
  
}
@media (prefers-color-scheme: dark){
  .uslugi-page-content{
  background:linear-gradient(30deg, rgba(53, 53, 53, 0.9),rgba(33, 33, 33, 0.9),rgba(60, 60, 60, 0.9)), url(@/../public/img/tekstura-bumagi.png) no-repeat;
  color: var(--light-text);
}
}
</style>