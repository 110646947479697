<template>
  <div class="app-wrap">
    <TopBar @showMenu="showMenu"/>
    <router-view/>
    <MobileMenu v-show="this.mobileMenu" @closeMenu="closeMenu"/>
    <FooterBlock/>
    <ScrollBar/>
  </div>
</template>

<script>
import MobileMenu from '@/components/MobileMenu.vue'
import TopBar from "@/components/TopBar.vue"
import FooterBlock from '@/components/footer/FooterBlock.vue'
import ScrollBar from '@/components/ScrollBar.vue'
export default {
  components:{TopBar,MobileMenu,FooterBlock,ScrollBar},
  data(){
    return{
      mobileMenu:false
    }
  },
  methods:{
    showMenu(){
      this.mobileMenu=true
      document.body.style.overflow='hidden'
    },
    closeMenu(){
      this.mobileMenu=false
      document.body.style.overflow=''
    }
  }
}
</script>
<style lang="scss">

</style>
