<template>
  <div class="footer-privacy">
      <div class="footer-privacy-name"><span> ©</span><span>Евгения Рябова 2023</span></div>
      <div class="footer-bottom__inn"><span>*</span><span>Является плательщиком налога на профессиональный доход (ИНН&nbsp;772857290413)</span></div>
      <a href="/oplata">Pеквизиты для оплаты</a>
  </div>
</template>

<script>
export default {

}
</script>

<style>
.footer-privacy{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 1rem;
  align-items: center;
  font-size: 14px;
}
.footer-bottom__bottom{
  display: flex;
}
.footer-bottom__bottom a{
  padding: 0 1rem;
}
.footer-privacy-name{
  font-size: clamp(1rem,3vh,1.5rem);
  font-family: var(--title-font);
}
.footer-bottom__inn{
  font-size: clamp(10px,2vh,1rem);
  font-style: italic;
  text-align: center;
  padding: 10px;
}
.footer-privacy a {
  color: var(--light-text);
}
</style>