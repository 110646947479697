<template>
  <UslugiPage />
</template>

<script>
import UslugiPage from '../components/Uslugi/UslugiPage.vue'
export default {
  components:{UslugiPage}

}
</script>

<style>

</style>