<template>
  <footer>
    <BottomForm/>
    <div class="dopros-ofer" v-show="$route.path == '/dopros'">
      <a href="ofer.pdf" target="blank">Оферта тренинга</a>
    </div>
    <FooterMenu/>
    <FooterContacts/>
    <FooterPrivacy/>
  </footer>
</template>

<script>
import BottomForm from '../footer/BottomForm.vue'
import FooterMenu from '../footer/FooterMenu.vue'
import FooterContacts from '../footer/FooterContacts.vue'
import FooterPrivacy from '../footer/FooterPrivacy.vue'
export default {
 components:{
    BottomForm,FooterMenu,FooterContacts,FooterPrivacy
 }
}
</script>

<style>
footer {
  background: linear-gradient(rgba(0, 0, 0, 0.92) 100%, rgba(0, 0, 0, .95) 0%) no-repeat;
  color: var(--light-text);
}
.dopros-ofer{
    padding: 0 1rem 1rem;
    margin: auto;
    text-align: center;
    font-size: 1.2rem;
}
.dopros-ofer a{
    color: var(--light-text);
}
</style>