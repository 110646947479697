<template>
  <div id="gadjet-form-wrap" class="main-form">
    <form id="gadjet-form">
      <div id="gadjet-form__title">Отправить заявку</div>
      <input type="tel" class="tel telnum" name="phone" placeholder="+7 (000) 000-00-00">
      <input type="text" placeholder="Ваше имя" class="name" name="name">
      <button class="gadjet-form__btn btn-prevent" id="gadjet-form__btn" @click.prevent="sendFormBtn($event.target)">ОТПРАВИТЬ</button>
      <a href="/sogl.html" class="form-sogl"><span> Нажимая отправить, Вы принимаете условия "Пользовательского соглашения".</span></a>
    </form>
  </div>      
</template>

<script>
import '../../../public/js/mail.js'
import { sendForm } from '../../../public/js/mail.js'
export default {
  methods:{
    sendFormBtn(e){
      sendForm(e)
    }
  }
}
</script>

<style>
#gadjet-form-wrap {
    background: rgba(19, 19, 19, 0.9);
    backdrop-filter: blur(5px);
    box-shadow: 1px 1px 5px black;
    padding: 10px;
    max-width: 300px;
    min-width: 180px;
    height: fit-content;
    min-height: fit-content;
    max-height: 500px;
    overflow: hidden;
    box-sizing: border-box;
}
#gadjet-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
#gadjet-form__title {
    font-size: clamp(12px, 2vw, 1.5rem);
    color: var(--light-text);
    font-family: var(--menu-font);
    text-transform: uppercase;
    text-align: center;
    padding: 5px;
}
#gadjet-form input,#gadjet-form button {
    outline: none;
    background: rgba(41, 41, 41, 0.9);
    backdrop-filter: blur(5px);
    box-shadow: -1px -1px 10px rgba(0, 0, 0, 0.5), 1px 1px 7px rgba(0, 0, 0, 0.5);
    border: none;
    padding: 10px;
    margin: 5px;
    color: whitesmoke;
    transition: transform 0.3s ease-in-out;
    position: relative;
    overflow: hidden;
    font-family: var(--menu-font);
}
.gadjet-form__btn {
  font-size: clamp(12px, 2vw, 1.5rem);
}
.form-sogl{
  font-size: clamp(8px, .7vw, 1rem);
  line-height:normal;
  font-style: italic;
  padding: 0 2rem 0 1rem;
  opacity: .7;
  color: var(--light-text);
}
@media(max-width:960px){
  #gadjet-form-wrap{
    max-width: none;
  }
  #gadjet-form__btn{
    font-size: clamp(12px, 6vw, 3rem)
  }
  #gadjet-form__title {
    font-size: clamp(12px, 5vw, 3rem);
  }
    .form-sogl{
    padding: 10px;
  }
  .form-sogl span{
    font-size: 12px;
  }
}
</style>