<template>
  <menu class="footer-menu">
    <nav class="footer-menu-nav" @click="topScroll">
      <router-link to="uslugi">Услуги</router-link>
      <router-link to="trenings">Тренинги</router-link>
      <router-link to="cases">Кейсы</router-link>
    </nav>
  </menu>
</template>

<script>
export default {
methods:{
  topScroll(){
    window.scrollTo(0,0)
  }
}
}
</script>

<style>
.footer-menu{
  display: flex;
  margin: 0;
  justify-content: center;
  padding:1rem;
  font-size: clamp(1rem,1.5vw,1.5rem);
}
.footer-menu a{
  padding: 0 10px;
  color: var(--light-text);
}
@media(max-width:760px){
  .footer-menu{
    flex-wrap: wrap;
  }
  .footer-menu div{
    flex-direction: 100%;
    text-align: center;
    padding: 1rem 0;
  }
  .footer-menu a{
    font-size: clamp(1rem, 3vh, 2rem);
  }
}
</style>