<template>
  <DoprosPage/>
</template>

<script>
import DoprosPage from '@/components/DoprosPage/DoprosPage.vue'
export default {
  components:{DoprosPage}
}
</script>

<style>

</style>